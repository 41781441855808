<template>
  <div class="mediaView hyundai">
    <!-- 미디어 뷰 타이틀 -->
    <div class="mediaTitle">
      <div class="container">
        <p class="subject">{{ currentData.Title }}</p>
        <p class="summary">{{ currentData.Topcontent }}</p>
        <div class="about">
          <div class="info">
            <span class="date">{{ currentData.RegiDate }}</span>
            <span class="view">{{ comma(Number(currentData.Hit)) }}</span>
          </div>
          <!-- 공유 -->
          <div class="share">
            <div class="shareBtn link" @click="clipboard">
              <span v-if="copyLink" class="message">주소가 복사되었습니다.</span>
            </div>
            <a href="#" class="shareBtn facebook" @click="shareFacebook"></a>
            <a href="#" class="shareBtn kakaotalk" @click="kakaoLink"></a>
          </div>
          <!-- //공유 -->
        </div>
      </div>
    </div>
    <!-- //미디어 뷰 타이틀 -->
    <div class="container">
      <!-- 미디어 본문 -->
      <div class="mediaContent">
        <img v-if="setImg(currentData.img) === false" style="display: none"/>
        <div class="img" v-else-if="currentData.img"><img :src="currentData.img" alt="" @error="replaceImg"></div>
        <div class="img" v-else><img src="../../assets/img/bg_media_404.png"></div>
        <div class="article">{{ currentData.Content }}</div>
        <div class="tags pretendard">
          <a href="#" class="tag" v-for="(data, idx) in tag" :key="idx">{{ data.name }}</a>
        </div>
       <a v-if="currentData.AttachFileNM && currentData.AttachFileNM !== 'http://www.hyundai-motorsfc.com/SoccerUpload/news/'"
          :href='currentData.AttachFileNM' download="fileName">첨부파일 : {{fileName}}</a>
      </div>
      <div class="mediaBtns">
        <router-link to="/ticket/service/notice" class="btn auto">목록으로</router-link>
      </div>
      <!-- //미디어 본문 -->
      <!-- 이전, 다음글 -->
      <div class="mediaRelated">
        <div class="row prev" v-if="previousData!==null">
          <p class="subject" @click="setRoute(previousData.category, previousData.seq)">
            <router-link v-if="previousData.category"
                         :to="{name: 'ticketServicePromotionView', params: { id: previousData.seq}}">
              {{ previousData.Title }}
            </router-link>
          </p>
          <span class="date">{{ previousData.RegiDate }}</span>
        </div>
        <div class="row noData" v-else>이전 게시물이 없습니다</div>
        <div class="row next" v-if="nextData!==null">
          <p class="subject" @click="setRoute(nextData.category, nextData.seq)">
            <router-link v-if="nextData.category"
                         :to="{name: 'ticketServicePromotionView', params: {id: nextData.seq}}">
              {{ nextData.Title }}
            </router-link>
          </p>
          <span class="date">{{ nextData.RegiDate ? nextData.RegiDate : null }}</span>
        </div>
        <div class="row noData" v-else>다음 게시물이 없습니다</div>
      </div>
      <!-- //이전, 다음글 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"

export default {
  name: 'TicketServicePromotionView',
  props: [
    'id',
    'media'
  ],
  created() {
    this.init()
  },
  watch: {
    paramsMedia() {
      this.init()
    },
    paramsId() {
      this.init()
    }
  },
  data() {
    return {
      copyLink: false,
      paramsMedia: this.$route.params.media,
      paramsId: this.$route.params.id,
      tag: [],
      currentData: [],
      previousData: [],
      nextData: [],
      latestData: [],
      file:'',
      fileName:'',
      isImg : true,
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    clipboard() {
      const url = window.location.href
      this.copyLink = true
      window.navigator.clipboard.writeText(url)
      setTimeout(() => {
        this.copyLink = false
      }, 1000)
    },
    async init() {
      const setStatus = (el, type) => {
        if(type=== 'currentData'){
          if(el.AttachFileNM){
            this.fileName = el.AttachFileNM.substr(50)
            this.file = el.AttachFileNM
          }
        }
        if (el.RegiDate) {
          el.RegiDate = dayjs(el.RegiDate).format('YYYY.MM.DD')
        }
        if (!el.TopImage||el.TopImage === "" || el.TopImage === "http://www.hyundai-motorsfc.com/uploadFile/data/") {
          el.img = el.PhotoFileNM
        }
        if (el.PhotoFileNM === "" || el.PhotoFileNM === "http://www.hyundai-motorsfc.com/uploadFile/data/") {
          el.img = el.TopImage
        }
        switch (el.status){
          case '1':
            el.status = '공지사항'
            el.category = 'notice'
            break
          case '2':
            el.status = '구단뉴스'
            el.category = 'news'
            break
          case '3':
            el.status = '경기사진'
            el.category = 'photo'
            break
        }
        return el
      }
      const data = await http.get(`/media/detail/${this.paramsMedia}/${this.paramsId}`)
      this.currentData = data.data.body.current.data
      setStatus(this.currentData, 'currentData')
      this.tag = data.data.body.current.tag
      this.previousData = data.data.body.previous
      if (this.previousData !== null) {
        setStatus(this.previousData)
      }
      this.nextData = data.data.body.next
      if (this.nextData !== null) {
        setStatus(this.nextData)
      }
      this.latestData = data.data.body.latest
      data.data.body.latest.map(row => {
        row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
        if (row.TopImage === "" || row.TopImage === "http://www.hyundai-motorsfc.com/uploadFile/data/") {
          row.img = row.PhotoFileNM
        }
        if (row.PhotoFileNM === "" || row.PhotoFileNM === "http://www.hyundai-motorsfc.com/uploadFile/data/") {
          row.img = row.TopImage
        }
        switch (row.Status){
          case '1':
            row.Status = '공지사항'
            row.category = 'notice'
            break
          case '2':
            row.Status = '구단뉴스'
            row.category = 'news'
            break
          case '3':
            row.Status = '경기사진'
            row.category = 'photo'
            break
        }
      })
    },
    setRoute(params, id) {
      this.$route.params.media = params
      this.$route.params.id = id
      this.paramsMedia = this.$route.params.media
      this.paramsId = this.$route.params.id
    },
    replaceImg(e) {
      e.target.src = bgImg
    },
    setImg(el){
      if(
          el === 'http://www.hyundai-motorsfc.com:80/uploadFile/Board/' ||
        el === 'http://www.hyundai-motorsfc.com/SoccerUpload/news/' ||
      el === 'http://www.hyundai-motorsfc.com/uploadFile/data/'
      ){
        return this.isImg = false
      } else {
        return this.isImg =true
      }

    },
    shareFacebook() {
      window.open('http://www.facebook.com/sharer.php?u=www.naver.com');
      // window.open(`http://www.facebook.com/sharer.php?u=실제서버/media/${this.paramsMedia}/${this.paramsId}`);
    },
    kakaoLink () {
      const url = `https://hyundai-motorsfc.com/media/${this.paramsMedia}/${this.paramsId}`
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.currentData.Title,
          description: this.currentData.Content,
          imageUrl: this.currentData.img,
          link: {
            mobileWebUrl: url,
            webUrl: url,
          },
        },
        buttons: [
          {
            title: '웹으로 보기',
            link: {
              mobileWebUrl: url,
              webUrl: url,
            },
          },
        ],
        installTalk: true,
          }
      )
    }
  }
}
</script>
<style>
.noData {
  justify-content: center;
}
</style>
