<template>
  <div class="matchPage gameRecord">
    <div class="innerBox">
      <div class="innerTitle">출전기록</div>
      <div class="rankingTable recordTable smallTable">
        <table>
          <thead>
            <tr>
              <th>이름</th>
              <th>출전</th>
              <th>교체인</th>
              <th>교체아웃</th>
              <th>득점</th>
              <th>도움</th>
              <th>슈팅<br>(S/T)</th>
              <th>파울(C/S)</th>
              <th>OS</th>
              <th>PK(S/F)</th>
              <th>경고</th>
              <th>퇴장</th>
              <th>실점</th>
              <th>자책</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, i) in recordData" :key="i">
              <td class="player bold">
                <div class="img"><img :src="data.imgPath" @error="nullImage"></div>
                <div class="info">
                  <div class="name">{{data.name}}</div>
                </div>
              </td>
              <td>{{data.Work_Des}}</td>
              <td>{{data.Change_i_Des}}</td>
              <td>{{data.Change_o_Des}}</td>
              <td>{{data.Goal_Qty}}</td>
              <td>{{data.Assist_Qty}}</td>
              <td>{{data.St_Qty}}/{{data.St_Valid_Qty}}</td>
              <td>{{data.Fo_Qty}}/{{data.Fs_Qty}}</td>
              <td>{{data.Os_Qty}}</td>
              <td>{{data.Pk_Y_Qty}}/{{data.Pk_N_Qty}}</td>
              <td>{{data.Warn_Qty}}</td>
              <td>{{data.Exit_Qty}}</td>
              <td>{{data.loss_Point}}</td>
              <td>{{data.Self_Goal_Qty}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="noData" v-if="recordData.length===0">데이터가 없습니다</div>
    </div>
  </div>
</template>

<script>
import imgPath from "@/library/imgPath"

export default {
  name: 'MatchGameRecord',
  created() {
    this.init()
  },
  watch:{
  },
  data(){
    return {
      recordData :[],
    }
  },
  methods: {
    async init(){
      const match = JSON.parse(JSON.stringify(this.$store.state.matchData)) // 깊은 복사
      match.record.player.map(row =>{
        row.imgPath = imgPath.set140px(row.Player_id)
        if (row.Change_i_Des === '') {
          row.Change_i_Des = '-'
        }
        if (row.Change_o_Des === '') {
          row.Change_o_Des = '-'
        }
      })
      this.recordData = match.record.player
    },
    nullImage (e) {
      e.target.src = imgPath.imgPath.player_null;
    }
  }
}
</script>

