import { render, staticRenderFns } from "./Youth.vue?vue&type=template&id=5dfe94ba&scoped=true"
import script from "./Youth.vue?vue&type=script&lang=js"
export * from "./Youth.vue?vue&type=script&lang=js"
import style0 from "./Youth.vue?vue&type=style&index=0&id=5dfe94ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dfe94ba",
  null
  
)

export default component.exports