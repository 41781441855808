import dayjs from "dayjs"

const thisYear = dayjs().format('YYYY')
export default {
    imgPath : {
        jbhd_s3: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/',
        player : `https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/images/player/${thisYear}/`,
        player_null: `https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/images/player/2024/null_140px.png`,
        staff: `https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/images/staff/${thisYear}/`,
        teamLogo : 'https://s3.ap-northeast-2.amazonaws.com/crm.thesports.io/img/common/emblem/',
        u_player: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/',
        video : 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/videos/',
        gamePhoto : 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/Board/011/',
        newGamePhoto : 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/newBoard/',
        playerVideo: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/BOARD/012/',
        playerNews: 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/SoccerUpload/news/',
        youTubeThumbnail_img : 'https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/BOARD/012/'
    },
    setProteamDetail : function (id){
        return `${this.imgPath.player}${id}.png`
    },
    setStaff : function (id){
        return `${this.imgPath.staff}${id}`
    },
    set140px : function (id){
        return `${this.imgPath.player}${id}_140px.png`
    },
    set280px : function (id){
        return `${this.imgPath.player}${id}_280px.png`
    },
    setTeamLogo : function (num){
        return `${this.imgPath.teamLogo}${num}.png`
    },
    setJbhd_S3 : function (el) {
        return `${this.imgPath.jbhd_s3}${el}`
    },
    setUPlayer : function (num) {
        return `${this.imgPath.u_player}${num}`
    },
    setVideo : function ( name ) {
        return `${this.imgPath.video}${name}.mp4`
    },
    setGamePhoto : function ( url ) {
        return  `${this.imgPath.gamePhoto}${url}`
    },
    setNewGamePhoto : function ( url ) {
        return  `${this.imgPath.newGamePhoto}${url}`
    },
    setPlayerVideo : function ( id ) {
        return `${this.imgPath.playerVideo}${id}`
    },
    setPlayerNews : function ( url ) {
        return `${this.imgPath.playerNews}${url}`
    },
    setYoutubeThumbnailImg : function ( url ) {
        return `${this.imgPath.youTubeThumbnail_img}${url}.jpg`
    }
}
