<template>
  <div class="qnaWrite">
    <div class="listInfo">
      <div class="title">1:1 문의하기 작성</div>
    </div>
    <div>
      <div class="writeTable">
        <div class="row">
          <div class="label">구분</div>
          <div class="cont">
            <select
                class="select_qna"
                :class="{ 'selected': category !== '' }"
                v-model="category"
                @change="changeArrow"
            >
              <option value="">선택하세요</option>
              <option v-for="(el, idx) in list" :value="el.value" :key="idx">{{el.label}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="label">제목</div>
          <div class="cont">
            <input v-model="title" class="input" placeholder="제목을 입력하세요" maxlength="50">
            <div class="letter"><strong>{{this.title.length}}</strong>/50</div>
          </div>
        </div>
        <div class="row">
          <div class="label">내용</div>
          <div class="cont">
            <textarea v-model="content" class="input textarea" placeholder="내용을 입력하세요"></textarea>
          </div>
        </div>
      </div>
      <div class="btns">
        <button @click="submit_event" class="btn primary2 w190 medium">작성하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/mypageApi'


export default {
  name: 'MypageActivityWrite',
  components:{},
  data() {
    return {
      title: "",
      content: "",
      list: [
        {label: '홈경기', value: 'home_match'},
        {label: '선수단', value: 'player'},
        {label: '시즌티켓/입장권', value: 'reserve'},
        {label: 'MD / 유니폼', value: 'MD'},
        {label: '원정경기', value: 'remote_match'},
        {label: 'WEB/APP 문의', value: 'service'},
        {label: '이벤트', value: 'event'},
        {label: '기타', value: 'ETC'},
      ],
      category: ''
    };
  },
  methods: {
    submit_event(){
      if (this.category.trim() === '') {
        alert('구분을 선택하셔야 합니다.');
        return;
      }

      //제목 길이 1글자 초과 50글자 미만 체크
      if(this.title.trim().length < 1 || this.title.trim().length > 50){
        alert('제목이 너무 길거나, 짧습니다.');
        return;
      }

      //제목 및 내용 확인
      if(this.category.trim() === '' || this.title.trim() === ""|| this.content.trim() === ""){
        alert('구분, 제목, 내용 모두 입력하셔야 합니다.');
        return;
      }else{
        return this.saveQna(this.title, this.content);
      }
    },

    //문의 내용 저장
    async saveQna(title, content){

      const qnaData = {
        title,
        content,
        category: this.category,
      };

      const res = await http.put('/inquiry', qnaData);
      if(res.data.success){
        alert('작성이 완료되었습니다.');
        this.$router.push('/mypage/activity/qna/1')
      }

      if(!res.data.success){
        alert('작성하는데 실패했습니다.');
        this.$router.push('/mypage/activity/qna/1')
      }
    },
    changeArrow () {
      document.activeElement.blur()
    }
  }
}
</script>
