<template>
<div class="recordIntro hyundai">
  <div class="introSection section1" :style="styleHeight">
    <div class="box">
      <div class="title">올시즌 관중</div>
      <p>{{recordData.lastGameDate}} 기준</p>
      <img v-for="(num, index) in imgToNum(recordData.totalAudience, 'number_audience')" :key="index" :src="num" class="imgNum" alt="" />
      <div class="scllor">
        <swiper class="swiper"
                :options="spectatorOption"
                ref="recordSwiper"
                v-if="isLoading">
          <swiper-slide
              v-for="(item, index) in recordData.audience"
              :key="index">
            <div class="audienceBox">
              <div class="img">
                <img :src="item.logo" alt=""/>
              </div>
              <span class="detail">VS&nbsp;<span>{{item.away_team_name}}</span></span>
              <div class="date">
                {{item.set_game_date}} {{item.game_time}}
              </div>
              <div class="count">
                <span>{{comma(item.audience)}}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev recordPrev" slot="button-prev"></div>
        <div class="swiper-button-next recordNext" slot="button-next"></div>
      </div>
    </div>
    <div>
      <button type="button" class="btnDown" @click="goNext"></button>
    </div>
  </div>
  <Record
  v-bind:record="recordData.record"
  v-bind:date="recordData.setKleagueGameDate"
  />
</div>
</template>

<script>
import dayjs from "dayjs"
import Record from "@/components/Record/Record"
import imgPath from "@/library/imgPath"
import regExp from "@/common/regExp"
import {Swiper, SwiperSlide} from "vue-awesome-swiper"
import http from '@/api/index'

export default {
  name: "RecordIntro",
  components: {
    Record,
    Swiper,
    SwiperSlide
  },
  computed : {
  styleHeight () {
    return {
      minHeight: this.sectionHeight
    }
  }
},
  created() {
    http.get(`/record`)
        .then(res => {
          const data = {
            totalAudience: 0,
            audience: [],
            record: {},
            lastGameDate : ''
          }
          data.totalAudience = res.data.data.total_audience
          data.audience = res.data.data.audience
          data.record = res.data.data.record
          // const date = res.data.data.last_game_date.game_date
          // data.lastGameDate = dayjs(date).format('YYYY.MM.DD')
          data.audience.map(row => {
            row.set_game_date = dayjs(row.game_date).format('YYYY.MM.DD')
            row.game_time = row.game_time.slice(0, 5)
            row.logo = imgPath.setTeamLogo(row.away_team)
          })
          data.lastGameDate = data.audience[0].set_game_date
          data.kleagueGameDate = res.data.data.last_game_date.game_date
          data.setKleagueGameDate = dayjs(data.kleagueGameDate).format('YYYY.MM.DD')
          this.recordData = data
          if(window.innerWidth < 769){
            this.spectatorOption.slidesPerView = 1
            } else {
            if (this.recordData.audience.length < 5) {
              this.spectatorOption.slidesPerView = this.recordData.audience.length
            } else {
              this.spectatorOption.slidesPerView = 5
            }
          }
          this.isLoading = true
        })
  },
  data() {
    return {
      recordData : {},
      isLoading : false,
      spectatorOption : {
        loop: false,
        slidesPerView: 0,
        centeredSlides: false,
        spaceBetween: 20,
        navigation: {
          nextEl: '.recordNext',
          prevEl: '.recordPrev'
        },
      },
    }
  },
  methods: {
    goNext () {
      const target = document.querySelector('.section2')
      if (target) {
        const top = target.offsetTop
        window.scrollTo(0, top)
      }},
    comma (num) {
      return regExp.comma(regExp.numberOnly(Number(num)))
    },
    imgToNum (num, path) { // 등번호 등 숫자를 하나씩 쪼개 이미지로 리턴
      const nums = `${this.comma(num)}`.split('')
      function getImg(n) {
        return require(`@/assets/img/${path || 'number'}/${n === ',' ? 'comma' : n}.png`)
      }
      return nums.map(x => getImg(x))
    },
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 80vw;
  padding-top: 80px;
  display: inline-block;
  .title {
    font-size: 36px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    margin-bottom: 88px;
  }
  img {
    height: 175px;
    margin-bottom: 124px;
  }
}
.scllor {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  .img {
    bottom: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    img{
      height: 50px;
      width: 50px;
      margin: 30px 0 0 0;
    }
  }
  .date {
    margin: 0;
    width: max-content;
    font-weight: 400;
    font-size: 0.8vw;
    line-height: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.25);
    padding-bottom: 20px;
  }
}

.scllor .count {
  display: flex;
  justify-content: center;
  color: #000000;
  font-size: 2.5vw;
  margin-bottom: 40px;
  span{
    margin-bottom: 20px;
    font-weight: 700;
  }
}

.scllor .detail {
  /*width: auto;*/
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  span {
    font-weight: 700;
    color: #000000;
    margin-bottom: 10px;
  }
}

.audienceBox {
  padding: 0px 40px;
  border-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.section1 {
  .swiper-button-prev,
  .swiper-button-next {
    width: 52px;
    height: 52px;
    margin-top: 24vh;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    &:after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: 10%;
    background-image: url('~@/assets/img/ico_arrow_left.png');
  }
  .swiper-button-next {
    right: 10%;
    background-image: url('~@/assets/img/ico_arrow_right.png');
  }

  @media screen and (max-width: 767px) {
    .box {
      padding-top: 10px;
      p {
        font-size: 16px;
        margin-bottom: 48px;
      }
    }
    .scllor {
      padding-left: 0px;
      padding-right: 0px;
      .date {
        width: auto;
        font-size: 15px;
      }
      .count{
        font-size: 40px;
      }
    }
    .audienceBox {
      display: block;
    }
    .imgNum {
      margin-bottom: 40px;
      height: 19.5vw;
    }

    .swiper-button-prev,
    .swiper-button-next {
      margin-top: 25vw;
      width: 30px;
      height: 30px;
    }

    .swiper-button-prev {
      left: 1%;
      background-image: url('~@/assets/img/ico_arrow_left.png');
    }
    .swiper-button-next {
      right: 1%;
      background-image: url('~@/assets/img/ico_arrow_right.png');
    }
  }
}

</style>
