<template>
  <div>
    <div class="btnDetail">
      <button
          v-for="(tab, i) in tabs"
          :key="i"
          :class="{lightgrey3: tab.id !== active}"
          :data-tab="tab.id"
          type="button"
          class="btn round"
          @click="setActive(tab.id)">
        <span class="hiddenMobile">{{ tab.name }}</span>
        <span class="displayMobile">{{ tab.name2 || tab.name }}</span><!-- 모바일용 이름 -->
      </button>
    </div>
    <!-- 리스트 정보 -->
    <div class="listInfo">
      <div class="sort">
        <span class="infoItem">
          <select v-model="currentMonth" class="select"
                  ref="select"
                  @change="setMonthData">
            <option value="">월선택</option>
            <option value="all">전체</option>
            <option
                v-for="(m, i) in 12"
                :key="i"
                :value="m">
              {{ m }}월
            </option>
          </select>
        </span>
        <span class="infoItem">
          <select v-model="currentYear"
                  class="select"
                  @change="init"
          >
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <!-- 리스트 -->
    <div class="matchList">
      <!-- 매치 -->
      <div class="item" v-for="(data, i) in setFilter === false ? matchData : filterData" :key="i">
        <div class="info">
          <div class="league" v-if="data.game_league_logo === 'etc'">{{data.meet_name}}</div>
          <div class="league" :data-league=data.game_league_logo v-else></div>
          <div class="time">{{ data.game_time }}</div>
          <div class="date">{{ data.game_date }} ({{ data.yoil }})</div>
          <div class="stadium">{{data.full_name}} ({{ data.ground }})</div>
        </div>
        <div class="game emblem">
          <div class="team left">
            <img :src="data.homeLogoImg" alt="">
            <span class="name">{{ data.home_team_name }}</span>
          </div>
          <div class="center">
            <span class="score ess">{{data.home_team_goal}}</span> vs <span class="score">{{data.away_team_goal}}</span>
            <div class="round"><span>{{data.set_league_round}}</span></div>
          </div>
          <div class="team right">
            <img v-if="data.away_team !== null" :src="data.awayLogoImg" alt="">
            <span class="name">{{ data.away_team_name }}</span>
          </div>
        </div>
        <div class="btns">
          <div class="row">
            <router-link class="btn green2" :to="`/match/game/${data.game_matchDate}/highlight?type=${data.game_league_set}`"
                         v-if="data.highlight !== null">하이라이트</router-link>
          </div>
          <div class="row">
            <router-link class="btn whiteLightgrey" :to="`/match/game/${data.game_matchDate}/summary?type=${data.game_league_set}`"
                         v-if="data.gameRecode && data.isKleage">기록</router-link>
            <router-link class="btn whiteLightgrey" :to="`/match/game/${data.game_matchDate}/review?type=${data.game_league_set}`"
                         v-if="data.review !== null">리뷰</router-link>
          </div>
        </div>
      </div>
      <!-- //매치 -->
    </div>
    <div class="noData" v-if="matchData === undefined|| matchData.length === 0">지난 경기 결과가 없습니다</div>
    <div class="noData" v-else-if="setFilter === true && filterData.length === 0">지난 경기 결과가 없습니다</div>
    <!-- //리스트 -->

  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from 'dayjs'
import imgPath from "@/library/imgPath"
import common from "@/library/common"

export default {
  name: 'MatchScheduleResult',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
    // this.currentMonth = dayjs().format('M')
    this.currentMonth = 'all' // 전체 디폴트 요청
    this.init()
  },
  data() {
    return {
      active: '',
      tabs: [
        {id: '', name: '전체대회'},
        {id: 'kleague', name: 'K LEAGUE', name2: 'K 리그'},
        {id: 'KOREAN CUP', name: 'KOREAN CUP'},
        {id: 'championsleague', name: 'ACL', name2: 'AFC CL'}
      ],
      currentYear: '',
      currentMonth:'',
      yearStart: 1995, // 필터 시작 년도
      year: [],
      fixData: [],
      matchData: [],
      filterData: [],
      setFilter: false,
      today : dayjs().format('YYYY-MM-DD'),
    }
  },
  methods: {
    async init() {
      const params = {
        params: {
          year: this.currentYear,
        }
      }
      const match = await http.get('/match/schedule/result', params)
      for (let i = 1; i <= 12; i++) {
        if (match.data.data[`${this.currentYear}-0${i}`]) {
          match.data.data[`${this.currentYear}-0${i}`].map(row => {
            this.setData(row)
          })
        }
        if (match.data.data[`${this.currentYear}-${i}`]) {
          match.data.data[`${this.currentYear}-${i}`].map(row => {
            this.setData(row)
          })
        }
      }

      this.fixData = JSON.parse(JSON.stringify(match.data.data));
      this.setMonthData();

    },
    setMonthData() {
      this.active = '';
      this.setFilter = false;
      const arr = []
      const _arr =[]
      const month = String(this.currentMonth)
      if (month === '') {
        return this.matchData
      }
      if (month === 'all') {
        for (let i = 1; i < 13; i++) {
          if(i < 10) {
            this.matchData = this.fixData[`${this.currentYear}-0${i}`]
            if (this.matchData !== undefined) {
              arr.push(this.matchData)
            }
          } else {
            this.matchData = this.fixData[`${this.currentYear}-${i}`]
            if (this.matchData !== undefined) {
              arr.push(this.matchData)
            }
          }
        }
        this.matchData = arr.flat(Infinity)
        this.matchData?.map((row) =>{
          if(dayjs(row.origin_game_date).isSameOrAfter(this.today)){
            _arr.push(row)
          }
        })
        this.matchData = common.sortGamesByDate(_arr) // 내림차순 요청
        return this.matchData
      }
      if (month === '10' || month === '11' || month === '12') {
        const _arr = this.fixData[`${this.currentYear}-${month}`]
        this.matchData = common.sortGamesByDate(_arr) // 내림차순 요청
        return this.matchData
      } else {
        const _arr = this.fixData[`${this.currentYear}-0${month}`]
        this.matchData = common.sortGamesByDate(_arr,) // 내림차순 요청
        return this.matchData
      }
    },
    setActive(e) {
      this.active = e
      let arr = []
      switch (e) {
        case 'kleague' :
          this.setFilter = true
          arr = this.matchData.filter(el => el.game_league === 'kleague')
          this.filterData = arr
          return this.filterData
        case 'KOREAN CUP' :
          this.setFilter = true
          arr = this.matchData.filter(el => el.game_league === 'KOREAN CUP')
          this.filterData = arr
          return this.filterData
        case 'championsleague' :
          this.setFilter = true
          arr = this.matchData.filter(el => el.game_league === 'afc')
          this.filterData = arr
          return this.filterData
        case '' :
          this.setFilter = false
      }
    },
    setData(el) {
      if (el.full_name) {
        el.full_name = el.full_name.replace(/\s/g, "")
      }
      el.league_round ?
          el.set_league_round = el.league_round+'R' :
          el.set_league_round = '종료'
      if (el.meet_seq >= 100) {
        el.game_type_name ?
            el.set_league_round = el.game_type_name :
            el.set_league_round = '종료'
      }
      el.game_time = el.game_time.substring(0, 5)
      el.game_matchDate = el.game_date
      el.game_date = dayjs(el.game_date).format("YYYY.MM.DD")
      const gameDateCheck = dayjs(el.game_matchDate).isAfter(dayjs("2013-01-01"))
      el.gameRecode = el.home_team_goal !== null && gameDateCheck
      el.home_team_name === '전북' ? el.ground = 'HOME' : el.ground = 'AWAY'
      el.isKleage = false
      if (el.away_team_name === '전북') {
        el.away_team_name = el.home_team_name
        el.home_team_name = '전북'
        el.home_team_ = el.home_team
        el.home_team = 'K05'
        el.away_team = el.home_team_
        el.home_team_goal_ = el.home_team_goal
        el.home_team_goal = el.away_team_goal
        el.away_team_goal = el.home_team_goal_
      }
      switch (el.meet_title) {
        case 'K League 1':
          el.game_league_logo = 'kleague'
          el.game_league = 'kleague'
          el.game_league_set = 'kleague'
          el.isKleage = true
          break
        case 'ACL':
          el.game_league_logo = 'afc'
          el.game_league = 'afc'
          el.game_league_set = 'afc'
          break
        case 'KOREAN CUP':
          el.game_league_logo = 'KOREAN CUP'
          el.game_league = 'KOREAN CUP'
          if (this.currentYear < 2024) {
            el.game_league_set = 'facup'
          } else {
            el.game_league_set = 'koreancup'
          }
          break
        case 'ETC':
          el.game_league_logo = 'etc'
          el.game_league = 'etc'
          el.game_league_set = 'etc'
          break
        case 'ACL2':
          el.game_league_logo = 'acl2'
          el.game_league = 'afc'
          el.game_league_set = 'afc2'
          break
        case 'ACLE':
          el.game_league_logo = 'acle'
          el.game_league = 'afc'
          el.game_league_set = 'afce'
          break
      }
      el.homeLogoImg = imgPath.setTeamLogo(el.home_team)
      if (el.away_team !== null) {
        el.awayLogoImg = imgPath.setTeamLogo(el.away_team)
      } else {
        el.awayLogoImg = ''
      }
      return el
    }
  }
}
</script>
