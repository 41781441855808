<template>
  <div>
    <div class="roulette" v-if="isWebView">
      <!-- 상단 캐릭터 컴포넌트 -->
      <GameCharacter
          style="margin-bottom: -40px;"
          :isQuiz="false"
          :data="data"
          :name="name"/>
      <!-- // 상단 캐릭터 컴포넌트 -->
      <!-- // 룰렛 게임 영역 -->
      <div class="roulette-container no-touch-callout mt45">
        <div class="rouletteArrow">
          <img src="@/assets/img/etc/roulette/roulette_arrow.png" alt="rouletteArrow" />
        </div>
        <div class="rouletteBox">
          <img ref="rouletteImage" src="@/assets/img/etc/roulette/rouletteImg.png" alt="rouletteImg" />
        </div>
        <div class="rouletteBtnBox cursorPointer" @click="rotate">
          START!
        </div>
      </div>
      <!-- // 룰렛 게임 영역  -->
      <!-- 룰렛 혜택안내 -->
      <div class="section mt45">
        <label class="label">룰렛 혜택안내</label>
        <div class="giftListBox" v-if="giftList.length !== 0">
          <div v-for="(data, idx) in giftList" :key="idx" class="giftList" :class="{ 'no-border': idx === giftList.length - 1 }">
            <div class="rightBox">
              <p class="title">{{ data.prize_title }}</p>
              <p style="font-weight: normal;">{{data._date}}</p>
            </div>
            <div class="leftBox">
              <img :src="data.prize_image" alt="">
            </div>
          </div>
        </div>
      </div>
      <!-- // 룰렛 혜택안내 -->
      <!-- // 룰렛 당첨내역 -->
      <div class="section mt45">
        <label class="label">룰렛 당첨 내역</label>
        <div class="historyBox" v-if="history.length !== 0">
          <div v-for="(data, idx) in history" :key="idx" class="historyList" :class="{ 'no-border': idx === history.length - 1 }">
            <div class="rightBox">
              <p class="title">{{ data.target_title }}</p>
              <p style="font-weight: normal; margin-bottom: 10px">{{ data.prize_title }}</p>
              <p style="font-weight: normal;">{{data._date}}</p>
            </div>
            <div class="leftBox">
              <img :src="data.prize_image" alt="">
            </div>
          </div>
        </div>
        <div v-else class="historyBox">
          <div class="historyList no-border">
            <p class="noHistory">룰렛 당첨 내역이 없습니다.</p>
          </div>
        </div>
      </div>
      <!-- // 룰렛 당첨내역 -->
      <!-- 퀴즈 안내사항 -->
      <div class="section mt45" v-if="caution">
        <label class="label">룰렛 안내사항</label>
        <div class="infoBox" v-html="caution"/>
      </div>
      <!-- // 퀴즈 안내사항 -->
      <GameAlert v-if="isOpen" :tmp="tmp" @offCooldown="offCooldown" @close="close" />
    </div>
    <div v-else style="height: 1000px;"></div>
  </div>
</template>

<script>
import GameCharacter from "@/components/Etc/GameCharacter.vue";
import GameAlert from "@/components/Etc/GameAlert.vue";
import http from "@/api/index";
import dayjs from "dayjs";

// ----- flutter event
let isAppReady = false;
window.addEventListener("flutterInAppWebViewPlatformReady", () => {
  isAppReady = true;
});
function toastMsg(msg) {
  if (isAppReady) {
    window.flutter_inappwebview.callHandler('toastMsg', msg )
  }
}
// ----- flutter event

export default {
  name: "RouletteView",
  components: {
    GameCharacter,
    GameAlert,
  },
  computed: {
    name() {
      return this.$store.state.user.name
    }
  },
  data() {
    return {
      isWebView : false,
      isOpen: false,
      tmp: {
        name: "roulette",
        title: "룰렛 결과",
        type: "roulette",
        icon: "roulette_correct",
        desc: "",
      },
      data: {
        date: "",
        title: "",
        team: "",
        deadline: "",
      },
      giftList: [],
      history: [],
      isCooldown: false,
      start_date: '',
      end_date: '',
      caution: '',
      userAgent: '',
      id: '',
      game_date: '',
      game_id: '',
      type: '',
    };
  },
  mounted() {
    this.userAgent = navigator.userAgent;
    if (this.userAgent.indexOf('JeonBukWebView') === -1) {
      alert('잘못된 접근입니다.')
      return window.location.href = '/'
    } else {
      this.chkChkIn()
    }
  },
  methods: {
    close() {
      this.isOpen = !this.isOpen;
    },
    chkChkIn () {
      http.get('/app/mypage/check_in')
          .then(res => {
            if(res.data.success === false) {
              alert('체크인이 필요합니다. 전북현대 앱을 확인해주세요.')
              return window.history.back()
            }
            this.isWebView = true
            this.id = this.$route.query.id
            this.game_id = this.$route.query.game_id
            this.game_date = this.$route.query.game_date
            this.init()
            this.historyInit()
          })
          .catch(err =>{
            alert('잠시 후에 다시 시도해주세요')
            console.log('>>>>>> err', err);
          })
    },
    init() {
      const id = this.id
      http
          .get(`/app/game/roulette?idx=${id}`)
          .then((res) => {
            const data = res.data.data
            data.target_subtitle ? this.data.subTitle = data.target_subtitle : this.data.subTitle =  '';
            this.data.title = data.target_title;
            this.start_date = dayjs(data.start_date)
            this.end_date = dayjs(data.end_date)
            this.data.deadline = dayjs(data.end_date).format("YYYY.MM.DD HH:mm");
            if (data.roulette_item) {
              // this.giftList = data.roulette_item.filter(prize => prize.prize_type !== 'fail');
              this.giftList = data.roulette_item
            }
            if (data.caution) {
              this.caution = data.caution.replace(/\n/g, "<br>");
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    historyInit() {
      http.get('/app/game/roulette/result')
          .then(res => {
            const history = res.data.data
            history.map(el => {
              el._date = dayjs(el.cdate).format('YYYY.MM.DD')
            })
            this.history = history
          })
          .catch(err => console.error(err))
    },
    rotate() {
      // 중복 방지
      if (this.isCooldown) {
        return
      }
      this.isCooldown = true
      const today = dayjs()

      if (today.isBefore(this.start_date)) {
        const msg = '이벤트 시작 전입니다.'
        this.isCooldown = false
        return toastMsg(msg)
      }
      if (today.isAfter(this.end_date)) {
        const msg = '종료된 이벤트입니다.'
        this.isCooldown = false
        return toastMsg(msg)
      }

      http.get(`/app/game/roulette/init/${this.id}`)
          .then(res => {
            const data = res.data;
            let prize = data.data.prizeInfo.prize_title;
            // this.tmp.title =  data.data.prizeInfo.prize_title;
            if (data.data.prizeInfo.prize_type === 'fail') {
              this.tmp.icon = 'quiz_wrong';
              prize = '다음 기회에 다시 도전해주세요!';
            } else {
              this.tmp.icon = 'roulette_correct'
            }
              const rouletteImage = this.$refs.rouletteImage;

              // transform과 transition을 초기화하여 애니메이션 재설정
              rouletteImage.style.transition = 'none';
              rouletteImage.style.transform = 'initial';

              // 브라우저가 스타일 변경을 인식하도록 약간의 딜레이를 줌
              setTimeout(() => {
                const ran = Math.floor(Math.random() * 6);
                const arc = 360 / 6;
                const rotateAmount = (ran * arc) + 3600 + (arc * 3) - (arc / 4);

                rouletteImage.style.transform = `rotate(${rotateAmount}deg)`;
                rouletteImage.style.transition = `transform 2s cubic-bezier(0.33, 1, 0.68, 1)`;

                setTimeout(() => {
                  this.tmp.desc = prize;
                  this.close();
                  this.historyInit()
                }, 2000); // 5초 후에 실행
              }, 20); // 20ms 딜레이
          })
          .catch(error => {
            setTimeout(() => {
              this.isCooldown = false;
            }, 1000)
            if (error.response.data.success === false) {
              const msg = '이미 참여하였습니다.';
              return toastMsg(msg)
            }
            console.error(error);
            const msg = '잠시 후 다시 시도해주세요.'
            return toastMsg(msg)
          });
    },
    offCooldown () { // 중복 방지
      this.isCooldown = false
    },
  },
};
</script>


<style lang="scss" scoped>
</style>

