<template>
  <div class="mediaList hyundai board">
    <div class="container">
      <!-- 공지 or 최근 미디어 -->
      <div>
        <router-link v-if="fixData.seq" :to="{name: 'mediaView', params: {id: fixData.seq, media: 'notice'}}">
          <div class="img" v-if="fixData.TopImage"><img :src="fixData.TopImage" @error="replaceImg"/></div>
          <div class="img" v-else><img src="../../assets/img/bg_media_404.png"></div>
          <div class="article">
            <div class="category"><span>공지사항</span></div>
            <p class="subject">{{ fixData.Title }}</p>
            <p class="desc">{{ stripTag(fixData.TopContent) }}</p>
            <div class="info">
              <span class="date">{{ fixData.RegiDate }}</span>
              <span class="view">{{ comma(Number(fixData.Hit)) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- //공지 or 최근 미디어 -->
      <!-- 미디어 리스트 -->
      <div class="tiles">
        <!-- 리스트 정보 -->
        <div class="tileInfo">
          <p class="total">총 <strong>{{ noticeCount }}개</strong>의 공지사항이 있습니다</p>
          <div class="sort">
            <div
                v-for="item in sort"
                :key="item.id"
                class="item"
                :class="{active: item.id === currentSort}"
                @click="setSort(item.id)">
              {{ item.name }}
            </div>
            <select v-model="currentYear">
              <option value="">년도선택</option>
              <option
                  v-for="(y, i) in year"
                  :key="i"
                  :value="y.id">
                {{ y.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- //리스트 정보 -->
        <!-- 미디어 타일 리스트 -->

        <div class="tileList" v-if="isData===true">
          <!-- tile item -->
          <div class="tile hasImage" v-for="(data,idx) in noticeData" :key="idx">
            <router-link :to="{name: 'mediaView', params: {id: data.seq, media: 'notice'}}">
              <div v-if="isImage(data.TopImage)" class="img"><img :src="data.TopImage"/></div>
              <!-- <div v-else><img src="../../assets/img/bg_media_404.png"></div> -->
              <div class="article" @click="getYCoordinate">
                <div class="category"><span>구단뉴스</span></div>
                <p class="subject">{{ data.Title }}</p>
                <p class="desc shortContent" v-html="data.Content"></p>
                <div class="info">
                  <span class="date">{{ data.RegiDate }}</span>
                  <span class="view">{{ comma(Number(data.Hit)) }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- //tile item -->
        </div>
        <div v-else class="noData">게시물이 없습니다.</div>
        <!-- //미디어 타일 리스트 -->
        <div class="viewMore">
          <button v-if="noticeData.length===0" style="display: none"></button>
          <button v-else-if="postInfo===true" type="button" class="btn whiteLightgrey btnViewmore" @click="appendList">
            더보기
          </button>
          <button v-else style="display: none"></button>
          <!--          <button v-if="postInfo" type="button" class="btn whiteLightgrey btnViewmore" @click="appendList">더보기</button>-->
        </div>
        <!-- 검색 -->
        <div class="filterBox">
          <div class="filter">
            <select
                v-model="type"
                class="select setSelect"
                ref="select"
            >
              <option value="title">제목</option>
              <option value="Content">내용</option>
            </select>
            <input class="input" placeholder="검색어를 입력하세요" v-model="search"/>
            <button type="button" class="searchBtn" @click="onSearch">검색</button>
          </div>
        </div>
        <!-- //검색 -->
      </div>
      <!-- //미디어 리스트 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"
import {stripTag} from '@/library/stripHtml'

export default {
  name: 'mediaNotice',
  watch: {
    currentYear() {
      this.scrollY = 0
      this.init(this.currentYear)
    },
    currentSort() {
      this.scrollY = 0
      this.init(this.currentSort)
    }
  },
  created() {
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    // this.initFix()
    this.type = this.$store.state.media.type;
    if (this.type === '') {
      this.type = 'title'
    }
  },
  mounted() {
    if (this.search !== '') {
      this.go()
    }
    this.init()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      let isNotice = ''
      // 이전 라우터 정보 접근
      if (from.fullPath.includes(to.fullPath)) {
        isNotice = true
      }
      if (isNotice === true) {
        vm.search = vm.$store.state.media.search;
        vm.page = vm.$store.state.media.page;
        vm.scrollY = vm.$store.state.media.scrollY;
        vm.currentSort = vm.$store.state.media.sort
        vm.currentYear = vm.$store.state.media.year
      } else {
        vm.$store.state.media.search = ''
        vm.$store.state.media.page = ''
        vm.$store.state.media.type = 'title'
        vm.type = 'title'
        vm.$store.state.media.scrollY = 0
        vm.$store.state.media.sort = 'latest'
        vm.$store.state.media.year = ''
        vm.clearSessionStorage()
      }
    });
  },
  computed: {
    stripTag() {
      return stripTag
    },
  },
  data() {
    return {
      currentSort: 'latest',
      currentYear: '',
      sort: [
        {id: 'latest', name: '최신순'},
        {id: 'views', name: '조회순'}
      ],
      yearStart: 2017, // 필터 시작 년도
      year: [
        {id: '', name: '전체'}
      ],
      dropdownActive: false,
      noticeData: [],
      noticeCount: 0,
      addPageData: [],
      fixData: [],
      page: 1,
      postInfo: true,
      isData: true,
      type: '',
      search: '',
      scrollY: 0
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    setSort(sort) {
      this.currentSort = sort
    },
    async initFix() {
      const fixData = await http.get('/media/list/notice')
      fixData.data.body.fix[0].RegiDate = dayjs(fixData.data.body.fix[0].RegiDate).format('YYYY.MM.DD')
      // this.fixData = fixData.data.body.fix[0]
    },

    // 세션 스토리지에 데이터를 저장하는 함수
    saveNoticeDataToSession(noticeData, postInfo, noticeCount, isData) {
      sessionStorage.setItem('noticeData', JSON.stringify(noticeData));
      sessionStorage.setItem('postInfo', JSON.stringify(postInfo));
      sessionStorage.setItem('noticeCount', noticeCount);
      sessionStorage.setItem('isData', isData);
    },

    // 세션 스토리지에서 데이터를 불러오는 함수
    loadNoticeDataFromSession() {
      const noticeData = JSON.parse(sessionStorage.getItem('noticeData'));
      const postInfo = JSON.parse(sessionStorage.getItem('postInfo'));
      const noticeCount = sessionStorage.getItem('noticeCount');
      const isData = sessionStorage.getItem('isData') === 'true';
      return {noticeData, postInfo, noticeCount, isData};
    },
    // 세션 스토리지를 비우는 함수
    clearSessionStorage() {
      sessionStorage.removeItem('noticeData');
      sessionStorage.removeItem('postInfo');
      sessionStorage.removeItem('noticeCount');
      sessionStorage.removeItem('isData');
    },

    async init(type, secType) {
      if (type === 'search') {
        this.page = 1
      }
      const params = {
        params: {
          inquire: this.currentSort,
          year: this.currentYear,
          page: this.page,
          searchType: this.type,
          searchValue: this.search
        }
      };
      // 세션 스토리지에서 데이터 불러오기
      const sessionData = this.loadNoticeDataFromSession();
      if (sessionData.noticeData && sessionData.noticeData.length > 0) {
        this.noticeData = sessionData.noticeData;
        this.postInfo = sessionData.postInfo;
        this.noticeCount = sessionData.noticeCount;
        this.isData = sessionData.isData;
        if (secType === 'reset') {
          setTimeout(()=> {
            this.go(0)
          }, 200)
        } else {
          setTimeout(()=> {
            const y = Number(this.$store.state.media.scrollY)
            this.go(y)
          }, 200)
        }
        // return;
      }

      if (this.$store.state.media.sort !== this.currentSort || this.$store.state.media.year !== this.currentYear) {
        if (type === this.currentSort || type === this.currentYear) {
          params.params.page = 1;
          this.page = 1

          if (type === this.currentYear) {
            this.currentSort = 'latest';
          }

          this.$store.state.media.sort = this.currentSort
          this.$store.state.media.year = this.currentYear

          const noticeData = await http.get('/media/list/notice', params);
          noticeData.data.body.list.forEach(row => {
            row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD');
          });

          this.noticeData = noticeData.data.body.list;
          this.noticeCount = noticeData.data.body.count;
          this.postInfo = noticeData.data.body.postInfo;
          this.isData = noticeData.data.body.list.length !== 0;

          // 세션 스토리지에 데이터 저장
          this.saveNoticeDataToSession(this.noticeData, this.postInfo, this.noticeCount, this.isData)
        }
      }


      if (type === 'search') {
        this.clearSessionStorage()
        params.params.page = 1;

        const noticeData = await http.get('/media/list/notice', params);
        noticeData.data.body.list.forEach(row => {
          row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD');
        });

        this.noticeData = noticeData.data.body.list;
        this.noticeCount = noticeData.data.body.count;
        this.postInfo = noticeData.data.body.postInfo;
        this.isData = noticeData.data.body.list.length !== 0;
        // 세션 스토리지에 데이터 저장
        this.saveNoticeDataToSession(this.noticeData, this.postInfo, this.noticeCount, this.isData)
      }

      if (type === this.page) {
        const addPageData = await http.get('/media/list/notice', params);
        addPageData.data.body.list.forEach(row => {
          row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD');
        });
        this.$store.state.media.page = this.page
        this.noticeData = this.noticeData.concat(addPageData.data.body.list);
        this.postInfo = addPageData.data.body.postInfo;

        // 세션 스토리지에 데이터 저장
        this.saveNoticeDataToSession(this.noticeData, this.postInfo, this.noticeCount, this.isData);
        return;
      }

      if (!sessionData.noticeData) {
        const noticeData = await http.get('/media/list/notice', params);
        noticeData.data.body.list.forEach(row => {
          row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD');
        });

        this.noticeData = noticeData.data.body.list;
        this.noticeCount = noticeData.data.body.count;
        this.postInfo = noticeData.data.body.postInfo;
        this.isData = noticeData.data.body.list.length !== 0;

        // 세션 스토리지에 데이터 저장
        this.saveNoticeDataToSession(this.noticeData, this.postInfo, this.noticeCount, this.isData)
      }
    },

    go(num) {
      if (num >= 0) {
        this.scrollY = num
      }
      return window.scrollTo({top: this.scrollY, behavior: 'smooth'})
    },

    replaceImg(e) {
      e.target.src = bgImg
    },
    appendList() {
      this.getYCoordinate()
      this.page = Number(this.page)
      this.page += 1
      this.init(this.page)
    },
    isImage(imgPath) {
      return regExp.image.test(imgPath.slice(imgPath.lastIndexOf('.') + 1).toLowerCase())
    },
    onSearch() {
      if (this.search === '') {
        alert('검색어를 입력하세요.')
        return
      }
      this.$store.state.media.type = this.type;
      this.$store.state.media.search = this.search;
      this.$store.state.media.page = this.page;
      this.init('search', 'reset')
    },
    getYCoordinate() {
      return this.$store.state.media.scrollY = window.scrollY;
    }
  },
}
</script>
<style>
.noData {
  text-align: center;
  color: #999999;
}
</style>
