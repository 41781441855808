<template>
  <div class="mediaList hyundai">
    <div class="container">
      <!-- 공지 or 최근 미디어 -->
      <div class="tile mainTile">
        <router-link v-if="fixData.seq" :to="{name: 'mediaView', params: {id: fixData.seq, media: fixData.category}}">
          <div v-if="fixData.img" class="img"><img :src="fixData.img" @error="replaceImg"/></div>
          <div v-else class="img"><img src="../../assets/img/bg_media_404.png"></div>
          <div class="article">
            <div class="category"><span>{{ fixData.status }}</span></div>
            <p class="subject">{{ fixData.title }}</p>
            <p class="desc">{{ stripTag(fixData.content) }}</p>
            <div class="info">
              <span class="date">{{ fixData.ctime }}</span>
              <span class="view">{{ comma(Number(fixData.hit)) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- //공지 or 최근 미디어 -->
      <!-- 미디어 리스트 -->
      <div class="tiles">
        <!-- 리스트 정보 -->
        <div class="tileInfo">
        </div>
        <!-- //리스트 정보 -->
        <!-- 미디어 타일 리스트 -->
        <div class="tileList all">
          <!-- tile item -->
          <div class="tile" v-for="(data,idx) in allMediaData" :key="idx">
            <router-link :to="{name: 'mediaView', params: {id: data.seq, media: data.category}}">
              <div v-if="data.img"><img :src="data.img" @error="replaceImg"/></div>
              <div v-else><img src="../../assets/img/bg_media_404.png"></div>
              <div class="article">
                <div class="category"><span>{{ data.status }}</span></div>
                <p class="subject">{{ data.title }}</p>
                <p class="desc" v-show="data.topContent">{{ data.topContent }}</p>
                <div class="info">
                  <span class="date">{{ data.ctime }}</span>
                  <span class="view">{{ comma(data.hit) }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- //tile item -->
        </div>
      </div>
      <!-- //미디어 리스트 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import { stripTag } from '@/library/stripHtml'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"

export default {
  name: 'MediaList',
  created() {
    this.init()
  },
  computed: {
    stripTag() {
      return stripTag
    },
  },
  data() {
    return {
      allMediaData: [],
      fixData: [],
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    async init() {
      let allMediaData = await http.get('/media/main')

      const setStatus = (el) => {
        let data
        if (el === 'allMediaData') {
          data = allMediaData.data.body.list
        }
        if (el === 'fixData') {
          data = allMediaData.data.body.fix
        }
        data.map(row => {
          row.ctime = dayjs(row.ctime).format('YYYY.MM.DD')
          row.seq = Number(row.seq)
          if (row.photofile === "" || row.photofile === "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/data/") {
            row.img = row.thumbnail
          }
          if (row.thumbnail === "" || row.thumbnail=== "https://jbhd-upload-file.s3.ap-northeast-2.amazonaws.com/uploadFile/SoccerUpload/news/") {
            row.img = row.photofile
          }
          if ( row.seq >= 100000) { // 게시물 10만번대 이후인 경우 사진파일 경로 고정
            const regex = /https:\/\/jbhd-upload-file\.s3\.ap-northeast-2\.amazonaws\.com\/uploadFile\/newBoard\//
            if (regex.test(row.photofile)) {
              row.img = row.photofile
            }
            if (regex.test(row.thumbnail)) {
              row.img = row.thumbnail
            }
          }
          switch (row.status){
            case '1':
              row.status = '공지사항'
              row.category = 'notice'
              break
            case '2':
              row.status = '구단뉴스'
              row.category = 'news'
              break
            case '3':
              row.status = '경기사진'
              row.category = 'photo'
              break
            case '6':
              row.status = 'JB 미디어'
              row.category = 'jbmedia'
              break
          }
        })
        return data
      }

      this.allMediaData = await setStatus('allMediaData')
      this.fixData = await setStatus('fixData')[0]
    },
    replaceImg(e) {
      e.target.src = bgImg
    }

  }
}
</script>

