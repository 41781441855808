<template>
  <div class="mediaList hyundai">
    <div class="container">
      <!-- 공지 or 최근 미디어 -->
      <div class="tile mainTile">
        <router-link v-if="fixData.seq" :to="{name: 'mediaView', params: {id: fixData.seq, media: 'news'}}">
          <div class="img" v-if="fixData.TopImage"><img :src="fixData.TopImage" @error="replaceImg"/></div>
          <div class="img" v-else><img src="../../assets/img/bg_media_404.png"></div>
          <div class="article">
            <div class="category"><span>구단뉴스</span></div>
            <p class="subject">{{fixData.Title}}</p>
            <p class="desc">{{ stripTag(fixData.TopContent) }}</p>
            <div class="info">
              <span class="date">{{ fixData.RegiDate }}</span>
              <span class="view">{{ comma(Number(fixData.Hit)) }}</span>
            </div>
          </div>
        </router-link>
      </div>
      <!-- //공지 or 최근 미디어 -->
      <!-- 미디어 리스트 -->
      <div class="tiles">
        <!-- 리스트 정보 -->
        <div class="tileInfo">
          <p class="total">총 <strong>{{ newsCount }}개</strong>의 구단뉴스가 있습니다</p>
          <div class="sort">
            <div
                v-for="item in sort"
                :key="item.id"
                class="item"
                :class="{active: item.id === currentSort}"
                @click="setSort(item.id)">
              {{ item.name }}
            </div>
            <select v-model="currentYear">
              <option value="">년도선택</option>
              <option
                  v-for="(y, i) in year"
                  :key="i"
                  :value="y.id">
                {{ y.name }}
              </option>
            </select>
          </div>
        </div>
        <!-- //리스트 정보 -->
        <!-- 미디어 타일 리스트 -->
        <div class="tileList" v-if="isData===true">
          <!-- tile item -->
          <div class="tile" v-for="(data,idx) in newsData" :key="idx">
            <router-link :to="{name: 'mediaView', params: {id: data.seq, media: 'news'}}">
              <div v-if="data.TopImage"><img :src="data.TopImage" @error="replaceImg"/></div>
              <div v-else><img src="../../assets/img/bg_media_404.png"></div>
              <div class="article">
                <div class="category"><span>구단뉴스</span></div>
                <p class="subject">{{ data.Title }}</p>
                <p class="desc">{{ data.Content }}</p>
                <div class="info">
                  <span class="date">{{ data.RegiDate }}</span>
                  <span class="view">{{ comma(Number(data.Hit)) }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- //tile item -->
        </div>
        <div v-else class="noData">게시물이 없습니다.</div>
        <!-- //미디어 타일 리스트 -->
        <div class="viewMore">
          <button v-if="newsData.length===0" style="display: none"></button>
          <button v-else-if="postInfo===true" type="button" class="btn whiteLightgrey btnViewmore" @click="appendList">더보기
          </button>
          <button v-else style="display: none"></button>
        </div>
      </div>
      <!-- //미디어 리스트 -->
    </div>
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from 'dayjs'
import bgImg from "@/assets/img/bg_media_404.png"
import { stripTag } from '@/library/stripHtml'

export default {
  name: 'mediaNews',
  watch: {
    currentYear() {
      this.init(this.currentYear)
    },
    currentSort() {
      this.init(this.currentSort)
    },
  },
  created() {
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.init()
    this.initFix()
  },
  computed: {
    stripTag() {
      return stripTag
    },
  },
  data() {
    return {
      currentSort: 'latest',
      currentYear: '',
      sort: [
        {id: 'latest', name: '최신순'},
        {id: 'views', name: '조회순'}
      ],
      yearStart: 2017, // 필터 시작 년도
      year: [
        {id: '', name: '전체'}
      ],
      dropdownActive: false,
      newsCount: 0,
      newsData: [],
      addPageData:[],
      fixData: [],
      page: 1,
      postInfo:true,
      isData:true,
    }
  },
  methods: {
    comma(num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    setSort(sort) {
      this.currentSort = sort
    },
    async initFix(){
      const fixData = await http.get('/media/list/news')
      fixData.data.body.fix[0].RegiDate = dayjs(fixData.data.body.fix[0].RegiDate).format('YYYY.MM.DD')
      this.fixData = fixData.data.body.fix[0]
    },
    async init(type) {
      const params = {
        params: {
          inquire: this.currentSort,
          year: this.currentYear,
          page: this.page,
        }
      }
      if (type === this.currentSort) {
        params.params.inquire = this.currentSort
        params.params.year = this.currentYear
        params.params.page = 1
      }
      if (type === this.currentYear) {
        this.currentSort = 'latest'
        params.params.inquire = this.currentSort
        params.params.year = this.currentYear
        params.params.page = 1
      }
      if (type === this.page) {
        params.params.inquire = this.currentSort
        params.params.year = this.currentYear
        params.params.page = this.page
        const addPageData = await http.get('/media/list/news', params)
        addPageData.data.body.list.map(row => {
          row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
        })
       return this.newsData = this.newsData.concat(addPageData.data.body.list)
      }

      const newsData = await http.get('/media/list/news', params)
      newsData.data.body.list.map(row => {
        row.RegiDate = dayjs(row.RegiDate).format('YYYY.MM.DD')
      })
      this.newsData = newsData.data.body.list
      this.newsCount = newsData.data.body.count
      this.postInfo = newsData.data.body.postInfo
      newsData.data.body.list.length === 0 ? this.isData = false : this.isData = true


    },
    replaceImg(e) {
      e.target.src = bgImg
    },
    appendList() {
      this.page += 1
      this.init(this.page)
    },
    isImage (imgPath) {
      const isImage = regExp.image.test(imgPath.slice(imgPath.lastIndexOf('.') + 1).toLowerCase())
      return isImage
    }
  },
}
</script>
<style>
.noData{
  text-align: center;
  color: #999999;
}
</style>
