<template>
  <div class="mainShop">
    <div class="container">
      <div class="sectionTitle">
        MD SHOP
        <div class="viewmore"><a target="_blank" href="https://shop.hyundai-motorsfc.com/">더보기</a></div>
      </div>
    </div>
    <!-- 유니폼 배너 -->
    <div class="banner">
      <div class="left">
        <div class="content">
          <a href="https://shop.hyundai-motorsfc.com/category/detail/128846854" target="_blank" class="btn transparentWhite">구매하기</a>
          <div class="name">2025 JBHD 필드<br />홈 유니폼 노마킹</div>
        </div>
        <!-- 이미지 교체 필요-->
        <img src="@/assets/img/img_uniform_left.png" alt="" />
        <!-- 이미지 교체 필요-->
      </div>
      <div class="title gmarket">
        <div class="point">
          2025<br />SEASON UNIFORM
        </div>
        COLLECTION
      </div>
      <div class="right">
        <!-- 이미지 교체 필요-->
        <img src="@/assets/img/img_uniform_right.png" alt="" />
        <!-- 이미지 교체 필요-->
        <div class="content">
          <a href="https://shop.hyundai-motorsfc.com/category/detail/128846940" target="_blank" class="btn transparentWhite">구매하기</a>
          <div class="name">2025 JBHD 필드<br />원정 유니폼 노마킹</div>
        </div>
      </div>
    </div>
    <!-- //유니폼 배너 -->
    <!-- GOODS -->
    <div class="goods">
      <div class="tabs pretendard">
        <div class="container noScroll">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            type="button"
            class="btnTab transparentLightgrey"
            :class="{active: tab.id === current}"
            @click="goodsTab(tab.id, tab.no)">
            {{tab.name}}
          </button>
        </div>
      </div>
      <div class="container">
        <div class="goodsList" v-if="filteredGood.length > 0">
          <div
            v-for="(item, index) in filteredGood"
            :key="index"
            class="goodsItem">
            <a :href="item.link" target="_blank">
              <div class="img"><img :src="item.img" alt=""></div>
              <div class="name">{{item.name}}</div>
              <div class="price">{{item.price}}</div>
            </a>
          </div>
        </div>
        <div style="margin-top: 30px" v-else class="noData">
          등록된 상품이 없습니다.
        </div>
      </div>
    </div>
    <!-- //GOODS -->
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import http from "@/api/shopby"

export default {
  name: 'MainShop',
  computed: {
    filteredGood() {
      if (this.screenWidth <= 790) {
        return this.goods.slice(0, 4);
      } else {
        return this.goods;
      }
    }
  },
  mounted() {
    // 화면 크기 변경 이벤트 리스너 추가
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 컴포넌트가 제거되기 전에 이벤트 리스너 제거
    window.removeEventListener('resize', this.handleResize);
  },
  created () {
    this.initCategory()
  },
  data () {
    return {
      tabs: [],
      current: 'all',
      goods: [],
      screenWidth: window.innerWidth,
      test: [],
      year: this.$store.state.thisYear
    }
  },
  methods: {
    init (categoryNos) {
      let categoryNum = categoryNos
      if (categoryNum === 'ALL') {
        categoryNum = ''
      }
      http.get(`/products/search?categoryNos=${categoryNum}&pageNumber=1&pageSize=8&order.by=RECENT_PRODUCT&order.direction=DESC&hasTotalCount=true`)
          .then(res => {
            const newGoods = []
            res.data.items.forEach((item) => {
              newGoods.push({
                img: item.listImageUrls[0],
                price: this.price(item.salePrice),
                name: item.productName,
                link: 'https://shop.hyundai-motorsfc.com/category/detail/' + item.productNo
              })
            });
            this.goods = newGoods
          })
          .catch(err => {
            console.error('err',err)
          })
    },
    initCategory () {
      http.get('/categories')
          .then(res => {
            const newCate = [{ no: '', id: 'all', name: 'ALL'},]
            res.data.multiLevelCategories.forEach((item) => {
              newCate.push({
                no: item.categoryNo,
                id: item.label.toLowerCase(),
                name: item.label,
              })
            });
            this.tabs = newCate
            this.init('')
          })
          .catch(err => {
            console.error('err',err)
          })
    },
    goodsTab (category, categoryNos) {
      this.current = category
      this.init(categoryNos)
    },
    price (price) {
      return `${regExp.comma(regExp.numberOnly(price))}원`
    },
    handleResize() {
      // 화면의 가로 길이를 업데이트
      this.screenWidth = window.innerWidth;
    }
  }
}
</script>
<style lang="scss">
.noScroll {
  overflow-x: auto;
  padding-bottom: 10px;
}
.container::-webkit-scrollbar {
  height: 4px;
}
.container::-webkit-scrollbar-track {
  background-color: transparent;
}
.container::-webkit-scrollbar-thumb {
  //background-color: #D9D9D9;
  background-color: transparent;
}
.container::-webkit-scrollbar-button {
  display: none;
}
</style>
